<template>
  <div class="d-flex flex-column align-center ">
    <template v-if="hasMedia">
      <template v-if="form.media?.is_new">
        <v-img :src="form.media.base64" height="150px" width="150px" :aspect-ratio="1" />
      </template>
      <template v-else>
        <v-img :src="form.media?.original_url" height="150px" width="150px" :aspect-ratio="1" />
      </template>
      <AppBtn class="mt-4" @click="handleDelete()" size="small">
        <v-icon class="mr-2" icon="mdi:mdi-close" />
        Remover
      </AppBtn>
    </template>
    <template v-else>
      <appCard class="d-flex flex-column justify-center align-center text-medium-emphasis"
        style="width: 150px; height: 150px;" @click="handleAddImage">
        <AppIcon name="hugeicons:image-add-02" />
        <small class="mt-2">Adicionar imagem</small>
      </appCard>
      <input class="d-none" ref="fileInput" type="file" accept=".jpg,.jpeg,.png" @change="handleImageInput" />
    </template>
  </div>
</template>

<script setup>
const categoryForm = useCategoryFormStore();

const { form, isInsert } = storeToRefs(categoryForm);

const fileInput = ref(null)

const hasMedia = computed(() => {
  return form.value.media && (form.value.media.is_new || form.value.media.original_url);
})

const handleAddImage = () => {
  fileInput.value.click()
}

onMounted(() => {
  setMedia()
})

const setMedia = () => {
  let medias = form.value.media || []

  let categoryMedia = medias.find(media => media.collection_name === 'category_image');

  if (categoryMedia) {
    form.value.media = categoryMedia;
  }
}

const handleImageInput = async (e) => {
  const file = e.target.files[0];
  const reader = new FileReader();

  reader.onload = (e) => {
    let image = {
      base64: e.target.result,
      name: file.name,
      size: file.size,
      type: file.type,
      is_new: true,
    };

    form.value.media = image;
  };

  reader.readAsDataURL(file);
}

const handleDelete = () => {
  if (form.value.media.id) {
    categoryForm.deleteImage(form.value.media.id)
    categoryForm.load(form.value.id)
  }

  form.value.media = null;
}
</script>
