<template>
  <v-dialog v-model="dialog" max-width="600px" :fullscreen="$vuetify.display.mobile">
    <v-card>
      <v-card-title class="pa-4 d-flex">
        Selecionar categoria
        <v-spacer></v-spacer>
        <AppAddBtn size="small" @click="handleAddNew()">Nova</AppAddBtn>
      </v-card-title>

      <v-divider></v-divider>
      <template v-if="hasAnyCategory">
        <v-card-text class="pa-4">
          <v-row class="mb-2">
            <v-col>
              <AppSearchField v-model="query.search" @update:modelValue="search(1)" :loading="loading" />
            </v-col>
          </v-row>
          <AppPagination :data="categories" v-model="query.page" @update:modelValue="search()">
            <v-list class="py-0">
              <template v-if="showCatgoryDefault">
                <v-list-item class="px-2" @click="handleStatcSelect">
                  <span>Produto sem categoria</span>
                  <template v-slot:append>
                    <v-icon color="disabled" icon="mdi:mdi-chevron-right" />
                  </template>
                </v-list-item>
                <v-divider></v-divider>
              </template>
              <template v-for="category of categories.data">
                <v-list-item class="px-2" @click="handleSelect(category)">
                  {{ category.name }}
                  <template v-slot:append>
                    <v-icon color="disabled" icon="mdi:mdi-chevron-right" />
                  </template>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-list>
          </AppPagination>
        </v-card-text>

        <v-divider></v-divider>
      </template>
      <template v-else>

        <div class="d-flex flex-column align-center mt-10 mb-10">
          <div class="mb-3">
            <v-avatar class="mr-4" color="grey-lighten-4" size="120">
              <v-icon size="80" color="grey-lighten-1" icon="mdi:mdi-tag-multiple-outline"></v-icon>
            </v-avatar>
          </div>
          <div class="mb-3">
            <h2 class="text-center">
              Cadastre sua primeira categoria
            </h2>
          </div>
          <div class="mb-10 ">
            <p class="text-medium-emphasis text-center">
              Separe seus produtos por categorias!
            </p>
          </div>
          <div>
            <AppAddBtn @click="handleAddNew()">Nova Categoria</AppAddBtn>
          </div>
        </div>

      </template>

      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn class="text-capitalize" variant="plain" text @click="dialog = false">
          <v-icon class="mr-2" icon="mdi:mdi-arrow-left" />
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <app-text-field v-bind="$attrs" :modelValue="selectText" @click="open()" label="Categoria" readonly clearable
    @click:clear="handleClear()">
    <template v-slot:append-inner>
      <v-icon icon="mdi:mdi-chevron-right" @click="open()" />
    </template>
  </app-text-field>
  <CategoryForm ref="form" @store="handleStore($event)" />
</template>

<script setup>
// const props = defineProps(['modelValue',showCatgoryStatc: { default: false }])
const props = defineProps({
  modelValue: {
    type: Object,
    default: null,
  },
  showCatgoryDefault: {
    type: Boolean,
    default: false,
  },
});

const $attrs = useAttrs();

const emit = defineEmits(["update:modelValue"]);

const form = ref(null);

const dialog = ref(false);

const categoryStore = useCategoryStore();

const { categories, query } = storeToRefs(categoryStore);

const loading = ref(false);


const hasAnyCategory = computed(() => {
  return categories.value.data.length > 0;
});

const select = computed({
  get() {
    if (props.modelValue && props.modelValue.id) {
      return props.modelValue;
    } else {
      return null;
    }
  },

  set(value) {
    if (value) {
      emit("update:modelValue", { id: value.id, name: value.name });
      return;
    }
    emit("update:modelValue", null);
  },
});

const selectText = computed(() => {
  if (select.value) {
    return select.value.name;
  } else {
    return null;
  }
});

const search = async (page = null) => {
  if (page) {
    query.value.page = page;
  }

  loading.value = true;

  await categoryStore.getCategories();

  loading.value = false;
};

const open = () => {
  categoryStore.$reset();

  search(1);

  dialog.value = true;
};

const handleStatcSelect = () => {
  handleSelect({ id: "000", name: "Produto sem categoria" });
};

const handleSelect = (category) => {
  select.value = category;

  dialog.value = false;
};

const handleClear = () => {
  select.value = null;

  dialog.value = false;
};

const handleAddNew = () => {
  form.value.open();
};

const handleStore = (item) => {
  handleSelect(item);
};
</script>