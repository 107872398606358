import { defineStore } from "pinia";

export const useCategoryFormStore = defineStore("categoryFormStore", {

  state: () => ({

    http: useNuxtApp().$http,

    form: {
      id: null,
      name: null,
      status: true,
      media: [],
    },

  }),

  getters: {
    isInsert() {
      return this.form.id === null;
    },
  },

  actions: {

    async load(id) {

      const data = await this.http.show("product/category", id);

      this.form = data.category

    },

    async store() {
      if (this.isInsert) {
        return await this.insert();
      }
      else {
        return await this.update();
      }
    },

    async insert() {

      const data = await this.http.store("product/category", this.form);

      return data.category

    },

    async update() {

      const data = await this.http.update("product/category", this.form.id, this.form);

      return data.category

    },

    async deleteImage(image) {

      const data = await this.http.destroy("product/category", image);

      return data.category

    },

  },
});
