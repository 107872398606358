<template>
  <v-text-field @input="search" :placeholder="placeholder" variant="solo-filled" flat hide-details="true" rounded="lg"
    density="compact" :loading="loading">
    <template #prepend-inner>
      <AppIcon class="text-disabled" name="hugeicons:search-01" />
    </template>
  </v-text-field>
</template>

<!-- <script>
export default {
  inheritAttrs: false,
};
</script> -->

<script setup>
import { debounce } from "lodash";

const prop = defineProps({
  loading: {
    default: false,
  },
  placeholder: {
    default: "Pesquisar...",
  },
});

const emit = defineEmits(["update:modelValue", "input"]);

const searchTerm = ref(null);

const search = (e) => {
  let term = e.target.value;
  debouncedSearch(term);
  // searchTerm.value = value;
};

watch(searchTerm, (value) => {
  debouncedSearch(value);
});

const debouncedSearch = debounce((value) => {
  emit("update:modelValue", value);
  emit("input", value);
}, 500);
</script>