<template>
  <app-dialog v-model="dialog" scrollable max-width="600px">
    <app-card>
      <v-card-title class="pa-4"> Categoria </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">
        <v-row>
          <v-col class="order-md-first order-last" cols="12">
            <v-row>
              <v-col>
                <AppTextField v-model="form.name" label="Nome" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-4">
                <AppNumberField label="Taxa de comissão" v-model="form.commission_rate" :reverse="false" suffix="%" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <AppSwitch v-model="form.status" label="Ativo" color="primary" />
              </v-col>
            </v-row>
          </v-col>
          <!-- FEATURE: SITEV2 -->
          <template v-if="$features.has('onlineCatalogPro')">
            <v-col>
              <CategoryImage :category="form" />
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end pa-4">
        <AppCancelBtn @click="dialog = false" />
        <AppConfirmBtn @click="handleStore()" />
      </v-card-actions>
    </app-card>
  </app-dialog>
</template>

<script setup>
const categoryForm = useCategoryFormStore();

const $loading = useLoading();

const emit = defineEmits(["store"]);

const dialog = ref(false);

const $features = useFeatures();

const $acl = useAcl();

const form = computed(() => categoryForm.form);

const open = async (id = null, params = null) => {

  categoryForm.$reset();

  if (id) {
    $loading.start();
    await categoryForm
      .load(id)
      .then((response) => $loading.stop())
      .catch((error) => $loading.stop());
  }

  if (params) {
    form.value.name = params.name;
  }

  dialog.value = true;
};

const handleStore = async () => {

  $loading.start();

  await categoryForm.store().then((response) => {

    emit("store", response);

    dialog.value = false;

    $loading.stop();
  })
    .catch((error) => {
      $loading.stop();
    });
};

defineExpose({
  open,
});
</script>

<style lang="scss" scoped></style>