<template>
  <v-dialog v-model="dialog" width="450px" persistent>
    <app-card>
      <v-card-text class="ma-0 pa-0 align-content-center justify-content-center">
        <div class="text-center">
          <div class="mt-8 mb-4">
            <!-- <v-icon size="80" color="warning">mdi:mdi-alert</v-icon> -->
            <v-avatar size="80" variant="tonal" color="error">
              <v-icon size="40" color="error" icon="mdi:mdi-calendar-alert-outline"></v-icon>
            </v-avatar>
          </div>
          <div class="mb-1">
            <h2>Seu boleto venceu!</h2>
          </div>
          <div class="mb-4 px-10">
            <span class="text-medium-emphasis">
              Realize o pagamento escaneando o QR code Pix
            </span>
          </div>
          <div>
            <div class="px-5 py-5 d-flex flex-md-row justify-md-space-between flex-column align-center">
              <div class="w-50 mb-2">
                <div class="mb-2">
                  <span class="text-medium-emphasis">Valor:</span>
                  <br />
                  <strong>
                    R$
                    {{ $format.decimal(invoice.charges[0].amount / 100) }}
                  </strong>
                </div>
                <div>
                  <span class="text-medium-emphasis">Vencimento:</span>
                  <br>
                  <strong>
                    {{ $format.dateBr(invoice.charges[0].due_date) }}
                  </strong>
                </div>
              </div>
              <div class="w-50 d-flex justify-center pa-md-5 pa-0">
                <v-img width="150" :src="invoice.charges[0].pix_qr_code" />
              </div>
            </div>
            <div class="mt-6">
              <small class="text-medium-emphasis">
                ou copie o código manualmente
              </small>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col md="6" cols="12">
            <AppBtn block prepend-icon="mdi:mdi-content-copy" color="primary" variant="outlined" class="text-capitalize"
              @click="handleCopy(invoice.charges[0].pix_copia_e_cola)">
              Copiar QR Code
            </AppBtn>
          </v-col>
          <v-col md="6" cols="12">
            <AppBtn block prepend-icon="mdi:mdi-file-document-outline" color="primary" class="text-capitalize"
              @click="handleOpenBoleto(invoice.charges[0].boleto_link)">
              Abrir boleto
            </AppBtn>
          </v-col>
          <v-col>
            <AppBackBtn text @click="dialog = false"> Ir para o sistema </AppBackBtn>
          </v-col>
        </v-row>
      </v-card-actions>
    </app-card>
  </v-dialog>
</template>
<script setup>
import { differenceInCalendarDays } from "date-fns";

const dialog = ref(false);

const subscriptionStore = useSubscriptionStore();

const {
  subscription,
  hasUnpaidInvoices,
  unpaidInvoices,
  isFreeAccount
} = storeToRefs(subscriptionStore);

const invoice = computed(() => {
  return subscription.value?.unpaid_invoices?.[0] || {};
});

const isBoleto = computed(() => {
  return invoice?.value?.payment_method === "boleto";
});

const hasOverdueInvoice = computed(() => {
  if (hasUnpaidInvoices.value && unpaidInvoices.value.length > 0) {
    let daysOverdue = differenceInCalendarDays(
      new Date(),
      new Date(unpaidInvoices.value[0].due_date)
    );
    return daysOverdue > 0;
  }
  return false;
});

const showAlert = computed(() => {

  if (isFreeAccount.value) {
    return false;
  }

  if (hasUnpaidInvoices.value && hasOverdueInvoice.value && isBoleto.value) {
    return true;
  }

  return false;
});

onMounted(() => {
  dialog.value = showAlert.value;
});

const handleCopy = (text) => {
  navigator.clipboard.writeText(text);
};

const handleOpenBoleto = (link) => {
  window.open(link, "_blank");
};
</script>

<style lang="scss" scoped></style>