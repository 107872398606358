<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <app-card>
      <v-card-text class="ma-0 pa-0 align-content-center justify-content-center">
        <div class="text-center">
          <div class="mt-8 mb-4">
            <AppIcon size="80" :color="daysToExpire < 0 ? 'error' : 'warning'" name="hugeicons:alert-01" />
          </div>
          <div class="mb-1">

            <h3>{{ text }}</h3>
          </div>
          <div class="mb-4 px-5">
            <span class="text-medium-emphasis">
              Realize a renovação do seu certificado digital para continuar emitindo notas fiscais.
            </span>
          </div>
          <div>
            <app-btn color="primary" @click="goToSettings">Configurações fiscais</app-btn>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <AppBackBtn @click="dialog = false" />
      </v-card-actions>
    </app-card>
  </v-dialog>
</template>
<script setup>
const dialog = ref(false);

import { differenceInDays } from 'date-fns';

const $router = useRouter();


const authStore = useAuthStore();

const company = computed(() => authStore.selectedCompany);

const certificate = computed(() => company.value?.certificate);

const daysToExpire = computed(() => differenceInDays(new Date(certificate.value?.valid_to_date), new Date()));

const inExpirationPeriod = computed(() => daysToExpire.value <= 30);

const text = computed(() => {

  if (daysToExpire.value < 0) {
    return 'Seu certificado digital expirou!';
  }

  return `Seu certificado digital expira em ${daysToExpire.value} dias!`;

});

onMounted(() => {
  if (inExpirationPeriod.value) {
    dialog.value = true;
  }
});

const goToSettings = () => {
  $router.push('/configuracoes/configuracoes-fiscais');
}
</script>